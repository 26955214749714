import { Component, OnInit, ViewChild } from "@angular/core";
import { EntryService } from "src/app/services/entry.service";
import { NotificationService } from "src/app/services/notification.service";
import { UserService } from "src/app/services/user.service";
import { IReportEmbedConfiguration, models } from 'powerbi-client';
import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import Translations from 'src/assets/json/EnumTranslations.json';
import { SalesService } from "src/app/services/sales.service";
import { Salesman } from "src/app/models/salesman";
import { Extensions } from "src/app/helpers/extensions";
import { Roles } from "src/app/admin/roles/roles.component";
import { forkJoin, Observable } from "rxjs";

@Component({
    selector: 'app-dashboard-salesmen',
    templateUrl: './dashboard-salesmen.component.html',
    styleUrls: ['./dashboard-salesmen.component.scss', '../dashboards.scss']
})


export class DashboardSalesmenComponent implements OnInit {
    department = Translations.Departments.Salesman.Description;
    departmentIcon = 'groups_2';
    salesmen: Salesman[];
    salesman: Salesman;
    powerBIReport: PowerBIReport;
    reportConfig: IReportEmbedConfiguration = {
        type: 'report',
        embedUrl: undefined,
        tokenType: models.TokenType.Embed,
        accessToken: undefined,
        settings: {
            layoutType: models.LayoutType.Custom,
            customLayout: {
                displayOption: models.DisplayOption.FitToWidth,
            }
        },
        hostname: 'https://app.powerbi.com',
    };
    eventHandlersMap = new Map([
        ['loaded', () => console.log('PBI: Report loaded')],
        ['rendered', () => console.log('PBI: Report rendered')],
        ['error', function (event) { console.error(`PBI: ${event.detail}`) }]
    ]);

    @ViewChild(PowerBIReportEmbedComponent) reportObj!: PowerBIReportEmbedComponent;

    constructor(private entryService: EntryService, private userService: UserService, private notificationService: NotificationService,
        private salesService: SalesService) { }

    ngOnInit() { }

    ngAfterViewInit() {
        if (this.reportObj != null) {
            let salesmenRequest: Observable<Salesman[]>;

            if (Extensions.isUserInRole(this.userService.authenticationValue, Roles.Admin)) {
                salesmenRequest = this.entryService.getSalesmen();
            }
            else if (Extensions.isUserInRole(this.userService.authenticationValue, Roles.PowerBIViewer)) {
                salesmenRequest = this.entryService.getSalesmen({ userID: this.userService.authenticationValue.id });
            }
            else {
                this.notificationService.error('Você não possui permissão para esse recurso.');
                return;
            }

            forkJoin([salesmenRequest, this.salesService.getPowerBIReport()]).subscribe({
                next: data => {
                    this.salesmen = data[0];
                    this.powerBIReport = { token: data[1].embedToken.token, reportID: data[1].embedReports[0].reportId, embedUrl: data[1].embedReports[0].embedUrl } as PowerBIReport;

                    if (this.salesmen?.length > 0) {
                        this.salesman = this.salesmen[0];
                        this.getReportConfig();
                    }
                    else {
                        this.notificationService.error('Nenhum representante encontrado.');
                    }
                }
            });
        }
    }

    getReportConfig() {
        this.reportConfig = {
            ...this.reportConfig,
            id: this.powerBIReport.reportID,
            embedUrl: this.powerBIReport.embedUrl,
            accessToken: this.powerBIReport.token,
            filters: [{
                $schema: 'http://powerbi.com/product/schema#basic',
                target: {
                    table: 'dRepresentantes',
                    column: 'CODIGO'
                },
                operator: 'In',
                values: [this.salesman?.code?.toString()],
                filterType: models.FilterType.Basic
            }]
        }

        this.forceIframesReloading();
    }

    forceIframesReloading() {
        const iframes: HTMLCollectionOf<HTMLIFrameElement> = document.getElementsByTagName('iframe');

        for (let i = 0; i < iframes.length; i++) {
            const iframe = iframes.item(i);
            const iframeSource = iframe?.src;

            if (iframeSource && iframeSource.length > 0) {
                iframe.src = '';

                setTimeout(() => {
                    if (iframe) {
                        iframe.src = iframeSource;
                    }
                }, 1000);
            }
        }
    }

    changeFilter() {
        const filter: models.IBasicFilter = {
            $schema: 'http://powerbi.com/product/schema#basic',
            target: {
                table: 'dRepresentantes',
                column: 'CODIGO'
            },
            operator: 'In',
            values: [this.salesman?.code?.toString()],
            filterType: models.FilterType.Basic
        };

        this.reportObj.getReport().updateFilters(models.FiltersOperations.Replace, [filter]);
    };

    goFullScreen() {
        this.reportObj.getReport().fullscreen();
    }
}

export interface PowerBIReport {
    token: string;
    reportID: string;
    embedUrl: string;
}