import moment from "moment";
import { Authentication } from "../models/authentication";
import { Period } from "../models/period";
import * as fileSaver from 'file-saver';

export module Extensions {
    export function isUserInRole(user: Authentication, role: string): boolean {
        return user?.roles.find(x => x === role) != null;
    }

    export function isUserInAnyRole(user: Authentication, roles: string[]): boolean {
        return user?.roles.some(r => roles.includes(r));
    }

    export function groupBy<T, K>(list: T[], getKey: (item: T) => K) {
        const map = new Map<K, T[]>();
        list.forEach((item) => {
            const key = getKey(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            }
            else {
                collection.push(item);
            }
        });
        return Array.from(map.values());
    }

    export function formatDate(date: any, isEndDate?) {
        return isEndDate ? moment(date).format('YYYY-MM-DDT23:59:59') : moment(date).format('YYYY-MM-DDT00:00:00');
    }

    export function convertImage(image) {
        return `data:image/jpeg;base64,${image}`;
    }

    export function isDateBetween(p1: Period, p2: Period) {
        return (new Date(p1.startDate) >= new Date(p2.startDate) && new Date(p1.endDate) <= new Date(p2.endDate));
    }

    export function distinct<T, K>(list: T[], getKey: (item: T) => K) {
        return list.map(getKey).filter((value, index, self) => self.indexOf(value) === index && ![null, undefined].includes(value));
    }

    export function distinctBy<T, K>(list: T[], getKey: (item: T) => K) : T[] {
        const keySet = new Set<K>();
        const distinctList: T[] = [];

        for (const item of list) {
            const key = getKey(item);

            if (key !== null && key !== undefined && !keySet.has(key)) {
                keySet.add(key);
                distinctList.push(item);
            }
        }

        return distinctList;
    }

    export function saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        fileSaver.saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
    }

    export function formatMoney(value) {
        return Math.abs(Number(value)) >= 1.0e+9

            ? `${Math.round(Math.abs(Number(value)) / 1.0e+9)}B`
            : Math.abs(Number(value)) >= 1.0e+6

                ? `${Math.round(Math.abs(Number(value)) / 1.0e+6)}M`
                : Math.abs(Number(value)) >= 1.0e+3

                    ? `${Math.round(Math.abs(Number(value)) / 1.0e+3)}K`

                    : Math.round(Math.abs(Number(value))).toString();

    }
}