import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { MarginPercentual } from "../models/margin-percentual";
import { SalesGoal } from "../models/sales-goal";
import { SalesGoalBaseData } from "../models/sales-goal-base-data";
import { SalesGoalNote } from "../models/sales-goal-note";
import { CustomerFundAllocation } from "../models/customer-fund-allocation";
import { CustomerFundAllocationBaseData } from "../models/customer-fund-allocation-base-data";
import { SalesGoalRevision } from "../models/sales-goal-revision";
import { SalesGoalRevisionReport } from "../models/sales-goal-revision-report";
import { ProductionGoal } from "../models/production-goal";
import { SalesGoalLog } from "../models/sales-goal-log";
import { SalesGoalsStatus } from "../models/sales-goals-status";
import { SaleSimulation } from "../models/sale-simulation";
import { Item } from "../models/item";

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  constructor(private http: HttpClient) { }

  getSalesGoals(params) {
    return this.http.get<SalesGoal[]>(`${environment.API}salesgoals`, { params }).pipe();
  }

  getSalesGoalsStatus(params) {
    return this.http.get<SalesGoalsStatus>(`${environment.API}salesgoals/status`, { params }).pipe();
  }

  getSalesGoalsLogs(params) {
    return this.http.get<SalesGoalLog[]>(`${environment.API}salesgoals/logs`, { params }).pipe();
  }

  getContributionMargins(params) {
    return this.http.get<MarginPercentual[]>(`${environment.API}salesgoals/margins`, { params }).pipe();
  }

  getGroupedSalesItems(params) {
    return this.http.get<any>(`${environment.API}salesgoals/items`, { params }).pipe();
  }

  getSalesGoalsBaseData(params) {
    return this.http.get<SalesGoalBaseData[]>(`${environment.API}salesgoals/base-data`, { params }).pipe();
  }

  postGoals(salesGoal) {
    return this.http.post<SalesGoal[]>(`${environment.API}salesgoals`, salesGoal).pipe();
  }

  putGoalsAmount(params) {
    return this.http.put<any>(`${environment.API}salesgoals/update-amounts`, params).pipe();
  }

  putGoalsQuantity(params) {
    return this.http.put<any>(`${environment.API}salesgoals`, params).pipe();
  }

  putGoalsContributionMargin(params) {
    return this.http.put<any>(`${environment.API}salesgoals/update-margins`, params).pipe();
  }

  deleteGoals(salesGoals) {
    return this.http.delete(`${environment.API}salesgoals`, { body: salesGoals }).pipe();
  }

  closeSalesGoals(params) {
    return this.http.put<any>(`${environment.API}salesgoals/finalize`, params).pipe()
  }

  getSalesGoalsNotes(params) {
    return this.http.get<SalesGoalNote[]>(`${environment.API}salesgoals/notes`, { params }).pipe();
  }

  postSalesGoalsNote(note) {
    return this.http.post<SalesGoalNote>(`${environment.API}salesgoals/notes`, note).pipe();
  }

  putSalesGoalsNote(params) {
    return this.http.put<any>(`${environment.API}salesgoals/notes`, params).pipe();
  }

  deleteSalesGoalsNote(params) {
    return this.http.delete(`${environment.API}salesgoals/notes`, { body: params }).pipe();
  }

  getCustomerFundAllocations(params) {
    return this.http.get<CustomerFundAllocation[]>(`${environment.API}customerFundAllocations`, { params }).pipe();
  }

  getPendingCustomerFundAllocations(params) {
    return this.http.get<CustomerFundAllocation[]>(`${environment.API}customerFundAllocations/pending`, { params }).pipe();
  }

  getCustomerFundAllocationsBaseData(params) {
    return this.http.get<CustomerFundAllocationBaseData[]>(`${environment.API}customerFundAllocations/base-data`, { params }).pipe();
  }

  generateCustomerFundAllocations(params) {
    return this.http.post<CustomerFundAllocationBaseData[]>(`${environment.API}customerFundAllocations/generate`, params).pipe();
  }

  deleteCustomerFundAllocations(params) {
    return this.http.delete(`${environment.API}customerFundAllocations/clear`, { params }).pipe();
  }

  getPowerBIReport() {
    return this.http.get<any>(`${environment.API}BI`).pipe();
  }

  getSalesGoalsRevisions(params) {
    return this.http.get<SalesGoalRevision[]>(`${environment.API}salesgoals/revisions`, { params }).pipe();
  }

  postSalesGoalsRevision(revision) {
    return this.http.post<SalesGoalRevision>(`${environment.API}salesgoals/revisions`, revision).pipe();
  }

  putSalesGoalsRevision(params) {
    return this.http.put<any>(`${environment.API}salesgoals/revisions`, params).pipe();
  }

  deleteSalesGoalsRevision(params) {
    return this.http.delete(`${environment.API}salesgoals/revisions`, { body: params }).pipe();
  }

  applySalesGoalsRevisions(revisions) {
    return this.http.post<SalesGoalRevisionReport[]>(`${environment.API}salesgoals/apply-revisions`, revisions).pipe();
  }

  getProductionGoals(params) {
    return this.http.get<ProductionGoal[]>(`${environment.API}salesgoals/production`, { params }).pipe();
  }

  getSaleSimulations(params) {
    return this.http.get<SaleSimulation[]>(`${environment.API}salesimulations`, { params }).pipe();
  }

  askForSaleSimulations(body) {
    return this.http.post<SaleSimulation>(`${environment.API}salesimulations/simulate`, body).pipe();
  }

  createSaleSimulation(body) {
    return this.http.post<any>(`${environment.API}salesimulations`, body).pipe();
  }

  updateSaleSimulation(simulationID: number, body) {
    return this.http.put<any>(`${environment.API}salesimulations/${simulationID}`, body).pipe();
  }

  deleteSaleSimulation(params) {
    return this.http.delete(`${environment.API}salesimulations`, { params }).pipe();
  }

  getCustomerSaleHistory(params) {
    return this.http.get<Item[]>(`${environment.API}salesimulations/sale-history`, { params }).pipe();
  }
}
