import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './public/login/login.component';
import { HomeComponent } from './home/home.component';
import { HomeLayoutComponent } from './layouts/home-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout.component';
import { ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './public/reset-password/reset-password.component';
import { ScheduleCargoComponent } from './public/schedule-cargo/schedule-cargo.component';
import { Roles } from './admin/roles/roles.component';
import { JobApplicationComponent } from './public/job-application/job-application.component';
import { JobApplicationFinishedComponent } from './public/job-application/job-application-finished/job-application-finished.component';


const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'entries',
        loadChildren: () => import('./entries/entries.module').then(m => m.EntriesModule),
        canActivate: [AuthGuard], data: { roles: [Roles.Admin, Roles.EntryAdmin] }
      },
      {
        path: 'logistics',
        loadChildren: () => import('./logistics/logistics.module').then(m => m.LogisticsModule),
        canActivate: [AuthGuard], data: { roles: [Roles.Admin, Roles.LogisticsAdmin, Roles.LogisticsUser] }
      },
      {
        path: 'accounting',
        loadChildren: () => import('./accounting/accounting.module').then(m => m.AccountingModule),
        canActivate: [AuthGuard], data: { roles: [Roles.Admin, Roles.AccountingAdmin, Roles.AccountingUser, Roles.HumanResourcesAdmin] }
      },
      {
        path: 'sales',
        loadChildren: () => import('./sales/sales.module').then(m => m.SalesModule),
        canActivate: [AuthGuard], data: { roles: [Roles.Admin, Roles.SalesAdmin, Roles.SalesUser] }
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canActivate: [AuthGuard], data: { roles: [Roles.Admin] }
      },
      {
        path: 'messages',
        loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'human-resources',
        loadChildren: () => import('./human-resources/human-resources.module').then(m => m.HumanResourcesModule),
        canActivate: [AuthGuard], data: { roles: [Roles.Admin, Roles.HumanResourcesAdmin] }
      },
      {
        path: 'pbi-embedded',
        loadChildren: () => import('./pbi-embedded/pbi-embedded.module').then(m => m.PbiEmbeddedModule),
        canActivate: [AuthGuard], data: { roles: [Roles.Admin, Roles.PowerBIViewer] }
      }
    ]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      },
      {
        path: 'schedule-cargo',
        component: ScheduleCargoComponent
      },
      {
        path: 'job-application',
        component: JobApplicationComponent
      },
      {
        path: 'job-application/done',
        component: JobApplicationFinishedComponent
      }
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
